import { h, VNode } from "preact";
import { useEffect, useState } from "preact/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PasswordStrengthBar from "../components/async/PasswordStrengthBar";
import shallow from "zustand/shallow";
import { useStore } from "../store";
import { getCSRFToken } from "../utils";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTrash, faCheck } from "@fortawesome/free-solid-svg-icons";

library.add(faTrash);
library.add(faCheck);

type UserSettingsProps = {
  redirectToChooseTenant: () => void;
};

export const UserSettings = ({
  redirectToChooseTenant,
}: UserSettingsProps): VNode => {
  const [userSettingsInit, setUserSettingsInit] = useState(false);
  const [settings, setSettings] = useState({
    currentPassword: "",
    newPassword: "",
    verifyNewPassword: "",
    twofactcode: "",
  });

  const {
    error,
    processing,
    passwordChanged,
    setError,
    changeUserPassword,
    userSettings,
    setUserSettings,
  } = useStore(
    (state) => ({
      error: state.errors.userSettings,
      processing: state.processing.userSettings,
      passwordChanged: state.responses.userSettings.passwordChanged,
      setError: state.setError,
      changeUserPassword: state.changeUserPassword,
      userSettings: state.userSettings,
      setUserSettings: state.setUserSettings,
    }),
    shallow
  );
  useEffect(() => {
    async function fetch_user_settings() {
      const response = await fetch("/user_settings", {
        method: "GET",
        headers: {
          "X-CSRF-Token": getCSRFToken(),
        },
      });
      if (response.ok) {
        const { first_name, last_name, email, is_sso } = await response.json();
        setUserSettings({
          name: `${first_name} ${last_name}`,
          email,
          isSso: is_sso,
        });
      } else {
        setError("userSettings", "Failed to fetch user settings!");
      }
    }
    if (!userSettingsInit) {
      setUserSettingsInit(true);
      fetch_user_settings();
    }
  }, [userSettingsInit, setError, setUserSettings]);

  const passwordsMatch = settings.newPassword === settings.verifyNewPassword;
  const passwordTooShort =
    settings.newPassword.length < 12 && settings.newPassword.length > 0;
  const passwordTooLong = settings.newPassword.length > 128;
  const newPasswordInputsAreEmpty =
    settings.newPassword === "" && settings.verifyNewPassword === "";
  const inputIsEmpty =
    settings.currentPassword === "" ||
    settings.newPassword === "" ||
    settings.verifyNewPassword === "";

  const [back, setBack] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const route = params.get("route");

    if (back) {
      if (route === "user-settings") {
        window.history.back();
        setTimeout(() => {
          redirectToChooseTenant();
        }, 800);
      } else {
        redirectToChooseTenant();
      }
    }
  }, [back, redirectToChooseTenant]);

  function handleChange(event: any) {
    const value =
      event.target.name === "newPassword"
        ? /* Replace multiple subsequent spaces with single spaces */
          (event.target.value || "").replace(/\s+/g, " ").trim()
        : event.target.value;

    setSettings({
      ...settings,
      [event.target.name]: value,
    });
  }

  return (
    <form
      action="/change_user_settings"
      onSubmit={changeUserPassword(settings)}
    >
      <div class="user-settings">
        <div class="responsive-half">
          <div class="section-info">
            <h1>User information</h1>
            <h2>
              <b>Name:</b> {userSettings.name}
              {!userSettings.name && <span class="loader ml-5" />}
            </h2>
            <h2>
              <b>E-mail:</b> {userSettings.email}
              {!userSettings.email && <span class="loader ml-5" />}
            </h2>
            {/* @todo: Add back when user info is modifiable
            <div class="input">
              <input
                name="username"
                id="signInFormUsername"
                type="text"
                placeholder="Name"
                value={userSettings.name}
                disabled
              />
            </div>

            <div class="input">
              <input
                name="e-mail"
                id="signInFormUsername"
                type="text"
                placeholder="Email address"
                value={userSettings.email}
                disabled
              />
            </div>
            */}
          </div>

          <div class="password">
            {userSettings.isSso === true && (
              <div>
                <h1>Single Sign-On (SSO)</h1>
                <p>
                  Successfully set up
                  <FontAwesomeIcon className="ml-5" icon="check" />
                  {!userSettings.isSso && <span class="loader ml-5" />}
                </p>
              </div>
            )}
            {userSettings.isSso === false && (
              <div>
                <h1>Change password</h1>

                <div class="input">
                  <input
                    name="currentPassword"
                    id="currentPassword"
                    onInput={handleChange}
                    onKeyUp={() => setError("userSettings", null)}
                    value={settings.currentPassword}
                    type="password"
                    required={true}
                    disabled={userSettings.isSso}
                    autocomplete="off"
                    placeholder="Current password"
                  />
                </div>

                <div class="input">
                  <input
                    name="newPassword"
                    id="newPassword"
                    onInput={handleChange}
                    onKeyUp={() => setError("userSettings", null)}
                    value={settings.newPassword}
                    type="password"
                    required={true}
                    disabled={userSettings.isSso}
                    autocomplete="new-password"
                    placeholder="New password"
                  />
                  <PasswordStrengthBar
                    password={settings.newPassword}
                    minLength={12}
                  />
                </div>

                <div class="input">
                  <input
                    name="verifyNewPassword"
                    id="verifyNewPassword"
                    onInput={handleChange}
                    value={settings.verifyNewPassword}
                    onKeyUp={() => setError("userSettings", null)}
                    type="password"
                    required={true}
                    disabled={userSettings.isSso}
                    autocomplete="new-password"
                    placeholder="New password again"
                  />
                </div>
              </div>
            )}
          </div>

          {/* @todo: Implement later
          <div class="twofactor">
            <h1>Two-factor authentication (2FA)</h1>
            <div class="instructions">
              <div class="install">
                <div class="install">
                  <p>Install one of the following applications:</p>
                  <ul>
                    <li>FreeOTP</li>
                    <li>Google Authenticator</li>
                  </ul>
                </div>
                <div class="open" />
                <p>Open the application and scan the following barcode:</p>
                <div class="enter" />
                <p>
                  Enter the one-time code provided by the application and click
                  "Save changes"
                </p>
                <div class="input">
                  <input
                    disabled
                    name="onetimepassword"
                    id="onetimePassword"
                    type="text"
                    placeholder="Code"
                  />
                </div>
              </div>
            </div>
          </div>
          */}
        </div>

        {/* @todo: Implement later
        <div class="responsive-half">
          <div class="sessions">
            <h1>Open sessions</h1>
            <div class="content">
              <div class="session">
                <div class="info">
                  <p class="name">Name</p>
                  <p class="signed">Signed on: </p>
                </div>
                <div class="trash">
                  <i>
                    <FontAwesomeIcon icon="trash" />
                  </i>
                </div>
              </div>
            </div>
            <div class="terminate">
              <a href="#">Terminate all sessions</a>
            </div>
          </div>
        </div>
        */}

        <div class="alerts">
          {passwordChanged && (
            <div class="success">
              <span class="text">Password changed succesfully!</span>
            </div>
          )}

          {/* @todo: Re-implement checks better when adding translation functionality */}

          {/* Backend checks */}
          {error && (
            <div class="error">
              {error === "PASSWORD_BREACHED" && (
                <span>
                  Your new password isn't secure. It has been part of a known
                  data breach and if you use the same password in any other
                  service, it should be changed immediately. Read more about
                  breached passwords from{" "}
                  <a
                    href="https://haveibeenpwned.com/FAQs"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Have I Been Pwned
                  </a>
                </span>
              )}
              {error == "PASSWORD_TOO_SHORT_OR_LONG" && (
                <span>Your new password is too short or too long!</span>
              )}
              {error == "INVALID_CURRENT_PASSWORD" && (
                <span>Your current password is not valid!</span>
              )}

              {[
                "PASSWORD_BREACHED",
                "PASSWORD_TOO_SHORT_OR_LONG",
                "INVALID_CURRENT_PASSWORD",
              ].indexOf(error) < 0 && <span>{error}</span>}
            </div>
          )}

          {/* Frontend checks */}
          {settings.verifyNewPassword.length > 0 &&
            !newPasswordInputsAreEmpty &&
            !passwordsMatch && (
              <div class="error">
                <strong>New password</strong> and{" "}
                <strong>New password again</strong> fields do not match.
              </div>
            )}
          {passwordTooShort && passwordsMatch && (
            <div class="error">Password is too short</div>
          )}
          {passwordTooLong && passwordsMatch && (
            <div class="error">Password is too long</div>
          )}
        </div>

        <div class="save">
          <a
            onClick={(e) => {
              e.preventDefault();
              setBack(true);
            }}
            class="back_button"
          >
            Back
          </a>

          <button
            name="saveSubmitButton"
            class="save_button"
            disabled={
              inputIsEmpty ||
              !passwordsMatch ||
              passwordTooShort ||
              passwordTooLong ||
              processing
            }
          >
            {processing && <span>Processing..</span>}

            {!processing && <span>Save changes</span>}
          </button>
        </div>
      </div>
    </form>
  );
};
